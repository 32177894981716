<template>
  <!-- 导航内容  -->
  <div class="content">
    <div class="top">
      <!--  数据中台  -->
      <div class="clearfix c_top_content">
        <div class="float-left">
          <ul>
            <li>PRODUCT</li>
            <li><h4>数据中台</h4></li>
            <!-- <li>Xiaoyang Data Hub</li> -->
          </ul>
          <div class="description">
            晓羊数据中台，是专门针对教育行业而设计的数据中台，更贴合学校的业务场景。帮助学校深入打通各业务系统间的数据孤岛，沉淀教育数据资产，以主题模型指导业务建设，用数据反哺业务进而加速学校数字化转型。基于对学校业务场景的深刻理解，最大化发挥出教育数据的价值。
          </div>
        </div>
        <img
          class="float-right"
          src="@/assets/images/schoolProduct/dataPlatform/data_platform.png"
          alt="数据中台"
        />
      </div>
    </div>
    <!--  客户痛点   -->
    <div class="customer_points">
      <div class="title">
        <h3>客户痛点</h3>
        <!-- <p class="title_en">Customer Pain Points</p> -->
      </div>
      <ul class="school_pictures">
        <li>
          <img
            src="@/assets/images/schoolProduct/dataPlatform/customer_points_01.png"
            alt=""
          />
          <div>
            <span>数据无法成为</span>
            <span>学校的教育资产</span>
          </div>
        </li>
        <li>
          <img
            src="@/assets/images/schoolProduct/dataPlatform/customer_points_02.png"
            alt=""
          />
          <div>
            <span>数据孤岛问题严重</span>
          </div>
        </li>
        <li>
          <img
            src="@/assets/images/schoolProduct/dataPlatform/customer_points_03.png"
            alt=""
          />
          <div>
            <span>多应用间基础数据</span>
            <span>无法统一</span>
          </div>
        </li>

        <li>
          <img
            src="@/assets/images/schoolProduct/dataPlatform/customer_points_04.png"
            alt=""
          />
          <div>
            <span>系统操作繁琐</span>
            <span>很难独立完成</span>
          </div>
        </li>
        <li>
          <img
            src="@/assets/images/schoolProduct/dataPlatform/customer_points_05.png"
            alt=""
          />
          <div>
            <span>业务数据无法流转</span>
          </div>
        </li>
        <li>
          <img
            src="@/assets/images/schoolProduct/dataPlatform/customer_points_06.png"
            alt=""
          />
          <div>
            <span>难以直观的凸显出</span>
            <span>信息化建设的价值</span>
          </div>
        </li>
      </ul>
    </div>
    <!--  晓羊中台价值  -->
    <div class="product_advantages">
      <div class="title">
        <h3>晓羊中台价值</h3>
        <!-- <p class="title_en">Xiaoyang Data Hub Value</p> -->
      </div>
      <div class="pc">
        <img
          src="@/assets/images/schoolProduct/dataPlatform/data_platform_value.png"
          alt="数据中台"
        />
      </div>
      <div class="mobile">
        <img
          src="@/assets/images/schoolProduct/dataPlatform/data_platform_value_5.png"
          alt="数据中台"
        />
      </div>
    </div>
  </div>
</template>

<script>
import productContentCharacteristicPc from '@/assets/images/schoolProduct/school/characteristic_pc.png'
import productContentCharacteristicMobile from '@/assets/images/schoolProduct/school/characteristic_mobile.png'
export default {
  name: 'DataPlatform',
  data() {
    return {
      productContentCharacteristicPc,
      productContentCharacteristicMobile,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/pages/product/school/DataPlatform.scss';
</style>
