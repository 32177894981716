<template>
  <!-- 导航内容  -->
  <div class="content">
    <!--  五育并举”评价系统  -->
    <div class="top">
      <!-- <div class="c_top">该产品已累计服务 <span>9K+</span>所学校</div> -->
      <div class="c_top_content">
        <div>
          <ul>
            <li>PRODUCT</li>
            <li><h4>校园智能物联管控平台</h4></li>
            <!-- <li>
              Xiaoyang Campus Energy Consumption Monitoring And Management
              System
            </li> -->
          </ul>
          <div class="description">
            校园智能物联管控平台:基干窄带物联网技术实现校园水能、电能的远程管理、状态监测、数据统计与分析。
          </div>
        </div>
      </div>
    </div>
    <img
      class="bigImg pc"
      src="@/assets/images/schoolProduct/Monitor/pic2.png"
      alt=""
    />
    <img
      class="bigImg mobile"
      src="@/assets/images/schoolProduct/Monitor/pic1.png"
      alt=""
    />
    <img
      class="bigImg mobile"
      src="@/assets/images/schoolProduct/Monitor/pic.png"
      alt=""
    />
    <!--  产品优势  -->
  </div>
</template>

<script>
import productContentCharacteristicPc from '@/assets/images/schoolProduct/fiveEdu/light.png'
import productContentCharacteristicMobile from '@/assets/images/schoolProduct/fiveEdu/light.png'
export default {
  name: 'OnePersonOneTimetable',
  data() {
    return {
      productContentCharacteristicPc,
      productContentCharacteristicMobile,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/pages/product/school/Monitor.scss';
</style>
