<template>
  <div class="school-product">
    <!--  头部轮播  -->
    <div id="swiper">
      <swiper ref="swiper" class="swiper" :options="swiperOption">
        <!--        <swiper-slide>-->
        <!--          <img-->
        <!--            src="@/assets/images/schoolProduct/banner_products_school.png"-->
        <!--            alt="校端产品 一人一课表"-->
        <!--          />-->
        <!--          <div class="top_title">-->
        <!--            <h3>校端产品 「 晓羊一人一课表 」</h3>-->
        <!--            <p>-->
        <!--              基于启发式搜索、基因算法、隐马尔科夫模型等核心算法，可支持任意形式的排课需求-->
        <!--            </p>-->
        <!--          </div>-->
        <!--        </swiper-slide>-->
        <swiper-slide v-for="(image, index) in swiperList" :key="index">
          <img :src="image.url" :alt="image.title" />
          <div class="top_title">
            <div>
              <h3 class="pc">{{ image.title }}{{ image.subTitle }}</h3>
              <h3 class="mobile">{{ image.title }}</h3>
              <h3 class="mobile">{{ image.subTitle }}</h3>
              <p>
                {{ image.desc }}
              </p>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <!--    <div class="top_banner">-->
    <!--            <img-->
    <!--              src="@/assets/images/schoolProduct/banner_products_school.png"-->
    <!--              alt=""-->
    <!--            />-->
    <!--      <div class="top_title">-->
    <!--        <h3>校端产品 「 晓羊一人一课表 」</h3>-->
    <!--        <p>-->
    <!--          基于启发式搜索、基因算法、隐马尔科夫模型等核心算法，可支持任意形式的排课需求-->
    <!--        </p>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  导航  -->
    <div class="navigation">
      <ul>
        <li
          v-for="(nav, index) in navList"
          :key="index"
          @click="toggleNav(index, nav)"
        >
          <span
            :class="{
              'active-nav': activeNav === index,
            }"
            :title="nav.title"
            >{{ nav.title }}</span
          >
        </li>
      </ul>
    </div>
    <!-- 导航内容  -->
    <component :is="navList[activeNav].component"></component>
    <!--    <onePersonOneTimetable></onePersonOneTimetable>-->
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { navList, swiperList } from '@/data/product/school'

export default {
  name: 'School',
  components: {
    swiper,
    swiperSlide,
  },

  data() {
    return {
      navList,
      activeNav: 0,
      swiperList, // 轮播图片
      // swiper操作项
      swiperOption: {
        // autoplay: {
        //   disableOnInteraction: false, // 用户操作后自动轮播时间重新开始计时
        //   delay: 5000, // 自动轮播切换时间
        // },
        autoplay: false,
        loop: true, // 开启循环
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          // dynamicBullets: true,
        },
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const componentName = to.query.name
      vm.navList.map((item, index) => {
        if (item.componentName === componentName) {
          vm.activeNav = index
          vm.$nextTick(() => {
            vm.$refs.swiper.swiper.slideTo(vm.activeNav + 1, 500)
          })
        }
      })
    })
  },
  watch: {
    $route(newVal) {
      const componentName = newVal.query.name
      this.navList.map((item, index) => {
        if (item.componentName === componentName) {
          this.activeNav = index
          this.$refs.swiper.swiper.slideTo(this.activeNav + 1, 500)
          this.$store.commit('setPcHeaderIsTransparent', true)
        }
      })
    },
  },
  methods: {
    toggleNav(index, item) {
      this.activeNav = index
      this.$refs.swiper.swiper.slideTo(this.activeNav + 1, 500)
      this.$router.push({
        path: '/products-plans/school',
        query: { name: item.componentName },
      })
    },
  },
  mounted() {
    // 设置header背景透明
    this.$store.commit('setPcHeaderIsTransparent', true)
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/product/school/index.scss';
</style>
