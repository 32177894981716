var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"product_advantages"},[_vm._m(2),_c('div',{staticClass:"product_content"},[_c('div',{staticClass:"characteristic"},[_c('img',{attrs:{"src":_vm.$store.state.deviceWidth > 768
              ? _vm.productContentCharacteristicPc
              : _vm.productContentCharacteristicMobile,"alt":""}}),_vm._m(3)]),_c('ul',{staticClass:"six_advantages"},_vm._l((_vm.opoAdvantages),function(item,index){return _c('li',{key:index},[_c('div',{class:`six_advantages_pic_${index + 1}`})])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"clearfix c_top_content"},[_c('div',{staticClass:"float-left"},[_c('ul',[_c('li',[_vm._v("PRODUCT")]),_c('li',[_c('h4',[_vm._v("一人一课表")])])]),_c('div',{staticClass:"description"},[_vm._v(" 晓羊“一人一课表”排课系统依托于强大的智能算法，充分利用教学资源，最大化满足教学、管理需求，让每一张课表都趋于最理想的状态。 ")])]),_c('img',{staticClass:"float-right",attrs:{"src":require("@/assets/images/schoolProduct/school/1_p_1_course.png"),"alt":"一人一课表"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"school_points"},[_c('div',{staticClass:"title"},[_c('h3',[_vm._v("学校痛点")])]),_c('ul',{staticClass:"school_pictures"},[_c('li',[_c('img',{attrs:{"src":require("@/assets/images/schoolProduct/school/points_1.png"),"alt":""}}),_c('div',[_c('span',[_vm._v("需要适应多种")]),_c('span',[_vm._v("走班策略排课")])])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/schoolProduct/school/points_2.png"),"alt":""}}),_c('div',[_c('span',[_vm._v("系统操作繁琐")]),_c('span',[_vm._v("很难独立完成")])])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/schoolProduct/school/points_3.png"),"alt":""}}),_c('div',[_c('span',[_vm._v("日常调代课")]),_c('span',[_vm._v("操作复杂")])])]),_c('li',[_c('img',{attrs:{"src":require("@/assets/images/schoolProduct/school/points_4.png"),"alt":""}}),_c('div',[_c('span',[_vm._v("排不出课表")]),_c('span',[_vm._v("无法找出原因")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("产品优势")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("特点")]),_c('p',[_vm._v(" 基于启发式搜索、基因算法、隐马尔科夫模型等核心算法,排课规则达到 "),_c('span',[_vm._v("100余种")]),_vm._v(" ，可支持任意形式的排课需求,简单易用，可完全交付的标准化产品。 ")])])
}]

export { render, staticRenderFns }