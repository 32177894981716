<template>
  <!-- 导航内容  -->
  <div class="content">
    <!--  一人一课表  -->
    <div class="top">
      <!-- <div class="c_top">该产品已累计服务 <span>9K+</span>所学校</div> -->
      <div class="clearfix c_top_content">
        <div class="float-left">
          <ul>
            <li>PRODUCT</li>
            <li><h4>学业质量管理平台</h4></li>
            <!-- <li>Xiaoyang One-Student-One-Schedule Course Scheduling System</li> -->
          </ul>
          <div class="description">
            <p class="pc">
              学业质量管理平台，专注于“双减”政策下，课后全场景学情数据的采集、分析与精准教学应用。
            </p>
            <p class="pc">
              以师生并进、共同成长为核心目标，专注于“双减”政策下的学业大数据采集、分析和应用，为学校开展大数据精准教学提供：控量提质分层作业管理服务、考试数据采集和学情分析服务、教研助力和数据解读服务、“贴身式”驻校服务，助学校打造大数据精准教学应用场景，基于大数据开展精准教学和分层辅导，提升教师的教学质量和学生的学习能力。
            </p>
            <p class="pc">
              控量提质管理系统中包含：考试平台（海量题库、智能组卷、高效阅卷、学情分析）、作业平台（教辅电子化、每日学情采集、每日学情分析、分层变式作业、个性化作业、阶段性报告）、错题本服务（班级共性错题、个性化错题）和大数据精准教学的服务。
            </p>
            <p class="mobile">
              学业质量管理平台，专注于“双减”政策下，课后全场景学情数据的采集、分析与精准教学应用。
            </p>
            <p class="mobile">
              以师生并进、共同成长为核心目标，专注于“双减”政策下的学业大数据采集、分析和应用，为学校开展大数据精准教学提供：控量提质分层作业管理服务、考试数据采集和学情分析服务、教研助力和数据解读服务、“贴身式”驻校服务，助学校打造大数据精准教学应用场景，基于大数据开展精准教学和分层辅导，提升教师的教学质量和学生的学习能力。
            </p>
            <p class="mobile">
              控量提质管理系统中包含：考试平台（海量题库、智能组卷、高效阅卷、学情分析）、作业平台（教辅电子化、每日学情采集、每日学情分析、分层变式作业、个性化作业、阶段性报告）、错题本服务（班级共性错题、个性化错题）和大数据精准教学的服务。
            </p>
          </div>
        </div>
        <img
          class="float-right"
          src="@/assets/images/schoolProduct/AcademicQuality/icon1.png"
        />
      </div>
    </div>
    <!--  学校痛点   -->
    <div class="school_points">
      <div class="title">
        <h3>产品体系</h3>
        <!-- <p class="title_en">School Pain Points</p> -->
      </div>
      <div class="school_pictures">
        <img src="@/assets/images/schoolProduct/AcademicQuality/pic.png" />
      </div>
    </div>
  </div>
</template>

<script>
import productContentCharacteristicPc from '@/assets/images/schoolProduct/school/characteristic_pc.png'
import productContentCharacteristicMobile from '@/assets/images/schoolProduct/school/characteristic_mobile.png'
import { opoAdvantages } from '@/data/product/school/index.js'
export default {
  name: 'OnePersonOneTimetable',
  data() {
    return {
      productContentCharacteristicPc,
      productContentCharacteristicMobile,
      opoAdvantages,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/pages/product/school/AcademicQuality.scss';
</style>
