<template>
  <!-- 导航内容  -->
  <div class="content">
    <!--  五育并举”评价系统  -->
    <div class="top">
      <!-- <div class="c_top">该产品已累计服务 <span>9K+</span>所学校</div> -->
      <div class="c_top_content">
        <div>
          <ul>
            <li>PRODUCT</li>
            <li><h4>智笔课堂互动系统</h4></li>
            <!-- <li>Smart Pen Classroom Interactive System</li> -->
          </ul>
          <div class="description">
            智笔课堂互动系统可以实现在智慧教学环境下以云资源为基础、以老师基于教室大屏的Windows端与学生(智慧笔+答题器)应用终端互动为手段、以数据分析及学生成长为核心，充分利用各种先进的信息化技术和丰富的教学资源让学生进行自主学习、主动发展、全面提升课堂的参与广度、学生的学习热度和思维深度，从而提高教师的教学效率。
          </div>
        </div>
      </div>
    </div>
    <div style="" class="c_content">
      <img src="@/assets/images/schoolProduct/smartClassRoom/pic1.png" alt="" />
    </div>
    <div style="" class="c_content">
      <img src="@/assets/images/schoolProduct/smartClassRoom/pic2.png" alt="" />
    </div>
    <div class="feeter">
      <div class="feeter-main">系统功能架构一贯穿课上、课下、形成教学闭环</div>
    </div>
    <!--  产品优势  -->
  </div>
</template>

<script>
import productContentCharacteristicPc from '@/assets/images/schoolProduct/fiveEdu/light.png'
import productContentCharacteristicMobile from '@/assets/images/schoolProduct/fiveEdu/light.png'
export default {
  name: 'OnePersonOneTimetable',
  data() {
    return {
      productContentCharacteristicPc,
      productContentCharacteristicMobile,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/pages/product/school/SmartClassroom.scss';
</style>
