<template>
  <!-- 导航内容  -->
  <div class="content">
    <!--  一人一课表  -->
    <div class="top">
      <!-- <div class="c_top">该产品已累计服务 <span>9K+</span>所学校</div> -->
      <div class="clearfix c_top_content">
        <div class="float-left">
          <ul>
            <li>PRODUCT</li>
            <li><h4>一人一课表</h4></li>
            <!-- <li>Xiaoyang One-Student-One-Schedule Course Scheduling System</li> -->
          </ul>
          <div class="description">
            晓羊“一人一课表”排课系统依托于强大的智能算法，充分利用教学资源，最大化满足教学、管理需求，让每一张课表都趋于最理想的状态。
          </div>
        </div>
        <img
          class="float-right"
          src="@/assets/images/schoolProduct/school/1_p_1_course.png"
          alt="一人一课表"
        />
      </div>
    </div>
    <!--  学校痛点   -->
    <div class="school_points">
      <div class="title">
        <h3>学校痛点</h3>
        <!-- <p class="title_en">School Pain Points</p> -->
      </div>
      <ul class="school_pictures">
        <li>
          <img src="@/assets/images/schoolProduct/school/points_1.png" alt="" />
          <div>
            <span>需要适应多种</span>
            <span>走班策略排课</span>
          </div>
        </li>
        <li>
          <img src="@/assets/images/schoolProduct/school/points_2.png" alt="" />
          <div>
            <span>系统操作繁琐</span>
            <span>很难独立完成</span>
          </div>
        </li>
        <li>
          <img src="@/assets/images/schoolProduct/school/points_3.png" alt="" />
          <div>
            <span>日常调代课</span>
            <span>操作复杂</span>
          </div>
        </li>

        <li>
          <img src="@/assets/images/schoolProduct/school/points_4.png" alt="" />
          <div>
            <span>排不出课表</span>
            <span>无法找出原因</span>
          </div>
        </li>
      </ul>
    </div>
    <!--  产品优势  -->
    <div class="product_advantages">
      <div class="title">
        <h3>产品优势</h3>
        <!-- <p class="title_en">Advantage</p> -->
      </div>
      <div class="product_content">
        <div class="characteristic">
          <img
            :src="
              $store.state.deviceWidth > 768
                ? productContentCharacteristicPc
                : productContentCharacteristicMobile
            "
            alt=""
          />
          <div>
            <span>特点</span>
            <p>
              基于启发式搜索、基因算法、隐马尔科夫模型等核心算法,排课规则达到
              <span>100余种</span>
              ，可支持任意形式的排课需求,简单易用，可完全交付的标准化产品。
            </p>
          </div>
        </div>
        <ul class="six_advantages">
          <li v-for="(item, index) in opoAdvantages" :key="index">
            <div :class="`six_advantages_pic_${index + 1}`">
              <!-- <i class="iconfont" :class="item.icon"></i>
              <div>
                <i class="iconfont icon-circle-line"></i>
                <span>{{ item.title }}</span>
              </div>
              <p>{{ item.content }}</p> -->
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import productContentCharacteristicPc from '@/assets/images/schoolProduct/school/characteristic_pc.png'
import productContentCharacteristicMobile from '@/assets/images/schoolProduct/school/characteristic_mobile.png'
import { opoAdvantages } from '@/data/product/school/index.js'
export default {
  name: 'OnePersonOneTimetable',
  data() {
    return {
      productContentCharacteristicPc,
      productContentCharacteristicMobile,
      opoAdvantages,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/pages/product/school/OnePersonOneTimetable.scss';
</style>
