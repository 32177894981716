<template>
  <!-- 导航内容  -->
  <div class="content">
    <!--  教师发展平台  -->
    <div class="top">
      <div class="clearfix c_top_content">
        <div class="float-left">
          <ul>
            <li>PRODUCT</li>
            <li><h4>教师发展平台</h4></li>
            <!-- <li>Teacher development platform</li> -->
          </ul>
          <div class="description">
            通过对学校各资源的整合，完善学校的教师专业发展体系，为教师提供培训资源、教学工具和学习平台，实现优质资源全体共享和校内日常工作效率的提升；同时强化培训实施的监管，对教师学习过程和效果进行综合评价，适时提供反馈和跟踪指导，促进学校发展。为教师日常教学提供优质、丰富的精品资源，在排课、备课、上课、作业、考试等方面提供全方位的工具和服务。并通过校内领导听课的指导评价及学科教研活动的开展，促进教师提高学科教学能力，获得专业成长。
          </div>
        </div>
      </div>
    </div>
    <!--  用户痛点   -->
    <div class="school_points">
      <div class="title">
        <h3>用户痛点</h3>
        <!-- <p class="title_en">User Pain Points</p> -->
      </div>
      <ul class="school_pictures">
        <li>
          <img
            src="@/assets/images/schoolProduct/teacher/points_1.png"
            alt=""
          />
          <div>
            <span>无法及时掌握、跟进、监管教师的学习及培训情况</span>
          </div>
        </li>
        <li>
          <img
            src="@/assets/images/schoolProduct/teacher/points_2.png"
            alt=""
          />
          <div>
            <span
              >无法及时对教师学习过程和效果进行综合评价，提供反馈和跟踪指导</span
            >
          </div>
        </li>
        <li>
          <img
            src="@/assets/images/schoolProduct/teacher/points_3.png"
            alt=""
          />
          <div>
            <span>缺乏分层类的精准学习和培训</span>
          </div>
        </li>

        <li>
          <img
            src="@/assets/images/schoolProduct/teacher/points_4.png"
            alt=""
          />
          <div>
            <span>缺乏明确的发展路径体系</span>
          </div>
        </li>
      </ul>
      <div class="pc">
        <img src="@/assets/images/schoolProduct/teacher/problem.png" alt="" />
      </div>
      <div class="mobile">
        <img src="@/assets/images/schoolProduct/teacher/problem-1.png" alt="" />
        <img src="@/assets/images/schoolProduct/teacher/problem-2.png" alt="" />
        <img src="@/assets/images/schoolProduct/teacher/problem-3.png" alt="" />
        <img src="@/assets/images/schoolProduct/teacher/problem-4.png" alt="" />
      </div>
    </div>
    <!--  核心功能  -->
    <div class="core_fun">
      <div class="title">
        <h3>核心功能</h3>
        <!-- <p class="title_en">Core Functions</p> -->
      </div>
      <img src="@/assets/images/schoolProduct/teacher/coreFun.png" alt="" />
    </div>
  </div>
</template>

<script>
import productContentCharacteristicPc from '@/assets/images/schoolProduct/school/characteristic_pc.png'
import productContentCharacteristicMobile from '@/assets/images/schoolProduct/school/characteristic_mobile.png'
export default {
  name: 'OnePersonOneTimetable',
  data() {
    return {
      productContentCharacteristicPc,
      productContentCharacteristicMobile,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/pages/product/school/Teacher.scss';
</style>
