<template>
  <!-- 导航内容  -->
  <div class="content">
    <!--  控量提质管理系统  -->
    <div class="top">
      <div class="c_top_content">
        <div>
          <ul>
            <li>PRODUCT</li>
            <li><h4>控量提质作业系统</h4></li>
            <!-- <li>Individualized Assignment Management System</li> -->
          </ul>
          <div class="description">
            <div class="pc">
              “双减”政策下的分层作业、弹性作业和个性化作业等精准教学应用，实现日常教学反馈数据采集，日常反馈数据支撑日常教学。<br />以师生并进、共同成长为核心目标，常态化采集日常作业数据，通过大数据帮助教师设计作业，建立校本作业体系，落实分层作业、弹性作业和个性化作业，通过学情大数据分析帮助学生查漏补缺，为教师提供精准学情分析、薄弱知识点分析、错题整理和归纳，科学高效的达成教学目标，减轻学生的课业负担，提升教师的教学效能，全面贯彻落实国家"双减"政策。
            </div>
            <p class="mobile first">
              “双减”政策下的分层作业、弹性作业和个性化作业等精准教学应用，实现日常教学反馈数据采集，日常反馈数据支撑日常教学。
            </p>
            <p class="mobile">
              以师生并进、共同成长为核心目标，常态化采集日常作业数据，通过大数据帮助教师设计作业，建立校本作业体系，落实分层作业、弹性作业和个性化作业，通过学情大数据分析帮助学生查漏补缺，为教师提供精准学情分析、薄弱知识点分析、错题整理和归纳，科学高效的达成教学目标，减轻学生的课业负担，提升教师的教学效能，全面贯彻落实国家"双减"政策。
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--   作业控量提质三部曲   -->
    <div class="school_points">
      <div class="title">
        <!-- <h3>作业控量提质三部曲</h3> -->
        <!-- <p class="title_en">
          A Trilogy Of Job Control Quantity And Quality Improvement
        </p> -->
      </div>
      <div class="school_points_content">
        <!-- <ul>
          <li>
            <i class="iconfont icon-check-line"></i>
            <span>不改变传统教学习惯</span>
          </li>
          <li>
            <i class="iconfont icon-check-line"></i>
            <span>不改变学生作业内容和作答方式</span>
          </li>
          <li>
            <i class="iconfont icon-check-line"></i>
            <span>不增加教师教学负担</span>
          </li>
          <li>
            <i class="iconfont icon-check-line"></i>
            <span>不增加学生学习负担</span>
          </li>
        </ul> -->
        <div class="pc">
          <div>
            <img
              src="@/assets/images/schoolProduct/qualityManageSystem/quality_main0.png"
            />
          </div>
          <div>
            <img
              class="sp"
              src="@/assets/images/schoolProduct/qualityManageSystem/quality_main1.png"
            />
          </div>
          <div>
            <img
              src="@/assets/images/schoolProduct/qualityManageSystem/quality_main3.png"
            />
          </div>
        </div>
        <div class="mobile">
          <img
            src="@/assets/images/schoolProduct/qualityManageSystem/quality_main0.png"
          />
          <img
            class="sp"
            src="@/assets/images/schoolProduct/qualityManageSystem/quality_main1.png"
          />
          <img
            src="@/assets/images/schoolProduct/qualityManageSystem/quality_main5.png"
          />
        </div>
      </div>
    </div>
    <!--  策学产品体系  -->
    <!-- <div class="ce_xue_system">
      <div class="title">
        <h3>策学产品体系</h3>
        <p class="title_en">Product System Of “Ce Xue”</p>
      </div>
      <div class="center">
        平台+服务 <span class="color-primary">3.0</span> 解决方案
      </div>
      <img
        src="@/assets/images/schoolProduct/qualityManageSystem/ce_xue_system_pc.png"
        alt=""
      />
    </div> -->
    <div class="ce_xue_system">
      <div class="title">
        <h3>产品亮点</h3>
        <!-- <p class="title_en">Product System Of “Ce Xue”</p> -->
      </div>
      <img
        src="@/assets/images/schoolProduct/qualityManageSystem/quality_main4.png"
        alt=""
        class="pc"
      />
      <img
        src="@/assets/images/schoolProduct/qualityManageSystem/quality_main6.png"
        alt=""
        class="mobile"
      />
      <img
        src="@/assets/images/schoolProduct/qualityManageSystem/quality_main7.png"
        alt=""
        class="mobile"
      />
      <img
        src="@/assets/images/schoolProduct/qualityManageSystem/quality_main8.png"
        alt=""
        class="mobile"
      />
    </div>
  </div>
</template>

<script>
import productContentCharacteristicPc from '@/assets/images/schoolProduct/school/characteristic_pc.png'
import productContentCharacteristicMobile from '@/assets/images/schoolProduct/school/characteristic_mobile.png'
export default {
  name: 'QualityManageSystem',
  data() {
    return {
      productContentCharacteristicPc,
      productContentCharacteristicMobile,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/pages/product/school/QualityManageSystem.scss';
</style>
