<template>
  <!-- 导航内容  -->
  <div class="content">
    <div class="top">
      <div class="clearfix c_top_content">
        <div class="float-left">
          <ul>
            <li>PRODUCT</li>
            <li><h4>晓羊学苑</h4></li>
            <!-- <li>Xiaoyang college</li> -->
          </ul>
          <div class="description">
            【晓羊学苑】是基于学校品牌建设和教师专业发展需要，提供优质的实践性教师学习资源和OMO特色教师培训服务为一体的专业网络平台。
          </div>
        </div>
        <img
          class="float-right"
          src="@/assets/images/schoolProduct/college/college_product.png"
        />
      </div>
    </div>
    <div class="course">
      <div class="title">
        <h3>课程模块</h3>
        <!-- <p class="title_en">Course module</p> -->
      </div>
      <img src="@/assets/images/schoolProduct/college/college_course.png" />
      <!-- <ul>
        <li>
          <div class="title">
            <i class="el-icon-check"></i>
            师资培训
          </div>
          <div class="content">
            <span>师德师风 </span>
            <span>反思能力</span>
            <span>礼仪培训</span>
            <span>教育理论</span>
            <span>师生关系</span>
            <span>小学培训</span>
            <span>高中培训</span>
            <span>专业发展</span>
            <span>发声技巧</span>
            <span>教育心理</span>
            <span>教学技能</span>
            <span>幼儿园培训</span>
            <span>初中培训</span>
          </div>
        </li>
        <li>
          <div class="title">
            <i class="el-icon-check"></i>
            专题培训
          </div>
          <div class="content">
            <span>教育科研 </span>
            <span>骨干教师</span>
            <span>三风建设</span>
            <span>名师培养</span>
            <span>家校共育</span>
            <span>新高考</span>
            <span>生涯规划</span>
            <span>班主任培训</span>
            <span>青年教育</span>
            <span>新教师培训</span>
            <span>教育信息化</span>
          </div>
        </li>
        <li>
          <div class="title">
            <i class="el-icon-check"></i>
            管理干部培训
          </div>
          <div class="content">
            <span>校长培训 </span>
            <span>督导培训</span>
            <span>财务后倾</span>
            <span>教研员培训</span>
            <span>中层干部</span>
            <span>安全管理</span>
          </div>
        </li>
        <li>
          <div class="title">
            <i class="el-icon-check"></i>
            专题培训
          </div>
          <div class="content">
            <span>特别教育 </span>
            <span>书法培训</span>
            <span>传统文化</span>
            <span>校本课程</span>
          </div>
        </li>
      </ul> -->
    </div>
    <!--  产品优势  -->
    <div class="product_advantages">
      <div class="title">
        <h3>产品优势</h3>
        <!-- <p class="title_en">Advantage</p> -->
      </div>
      <div class="product_content">
        <ul class="six_advantages">
          <li v-for="(item, index) in 6" :key="index">
            <div :class="`six_advantages_pic_${index + 1}`">
              <!-- <i class="iconfont icon-Cube-line"></i>
              <p>让教师能够认识到自身的长处及短板，及时进行调整。</p> -->
            </div>
          </li>
          <!-- <li>
            <div>
              <i class="iconfont icon-star-smile-line"></i>
              <p>OMO培训突破混合式培训的线上线下两张皮问题。</p>
            </div>
          </li>
          <li>
            <div>
              <i class="iconfont icon-curriculum-line"></i>
              <p>混合式效果1+1＜2，OMO效果1+1＞2。</p>
            </div>
          </li>
          <li>
            <div>
              <i class="iconfont icon-curriculum-search-line"></i>
              <p>真正做到国家对教师培训进行精准培训、精准评价的要求</p>
            </div>
          </li>
          <li>
            <div>
              <i class="iconfont icon-paper-line"></i>
              <p>不再是一次一抛的激情式培训，而是进阶式的能力素养提升培训。</p>
            </div>
          </li>
          <li>
            <div>
              <i class="iconfont icon-Cube-line"></i>
              <p>让教师能够认识到自身的长处及短板，及时进行调整。</p>
            </div>
          </li>
          <li>
            <div>
              <i class="iconfont icon-finger-circle-line"></i>
              <p>帮助教师对自身的专业发展和职业规划有据可依、有点可抓。</p>
            </div>
          </li> -->
        </ul>
        <img
          src="@/assets/images/schoolProduct/college/college_bottom.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import productContentCharacteristicPc from '@/assets/images/schoolProduct/school/characteristic_pc.png'
import productContentCharacteristicMobile from '@/assets/images/schoolProduct/school/characteristic_mobile.png'
export default {
  name: 'OnePersonOneTimetable',
  data() {
    return {
      productContentCharacteristicPc,
      productContentCharacteristicMobile,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/pages/product/school/College.scss';
</style>
