import onePersonOneClass from '@/assets/images/schoolProduct/banner_products_school.png'
import dataPlatform from '@/assets/images/schoolProduct/banner_data_plaform.png'
import manageSystem from '@/assets/images/schoolProduct/banner_manage_system.png'
// import evaluateSystem from '@/assets/images/schoolProduct/banner_evaluate_system.png'
import collegeSystem from '@/assets/images/schoolProduct/banner_college_system.png'
import teacherSystem from '@/assets/images/schoolProduct/banner_teacher_system.png'
import smartRoom from '@/assets/images/schoolProduct/banner_smart_room.png'
import safetyschool from '@/assets/images/schoolProduct/banner_safety_school.png'
// import aiScreen from '@/assets/images/schoolProduct/banner_ai_screen.png'
// import aiBlack from '@/assets/images/schoolProduct/banner_black.png'
import manager from '@/assets/images/schoolProduct/banner_manager.png'
import qcademicQuality from '@/assets/images/schoolProduct/banner_academic_quality.jpg'
import opotc1 from '@/assets/images/schoolProduct/opotc/1.png'
import opotc2 from '@/assets/images/schoolProduct/opotc/2.png'
import opotc3 from '@/assets/images/schoolProduct/opotc/3.png'
import opotc4 from '@/assets/images/schoolProduct/opotc/4.png'
import opotc5 from '@/assets/images/schoolProduct/opotc/5.png'
import opotc6 from '@/assets/images/schoolProduct/opotc/6.png'

import onePersonOneTimetableComponent from '@/views/productsPlans/schoolComponents/OnePersonOneTimetable'
import dataPlatformComponent from '@/views/productsPlans/schoolComponents/DataPlatform'
import qualityManageSystem from '@/views/productsPlans/schoolComponents/QualityManageSystem'
// import fiveEduComment from '@/views/productsPlans/schoolComponents/FiveEduComment'
import smartClassroom from '@/views/productsPlans/schoolComponents/SmartClassroom'
import safetySchool from '@/views/productsPlans/schoolComponents/SafetySchool'
// import aICloudScreen from '@/views/productsPlans/schoolComponents/AICloudScreen'
// import AICloudBlackboard from '@/views/productsPlans/schoolComponents/AICloudBlackboard'
import Monitor from '@/views/productsPlans/schoolComponents/Monitor'
import College from '@/views/productsPlans/schoolComponents/College'
import Teacher from '@/views/productsPlans/schoolComponents/Teacher'
import AcademicQuality from '@/views/productsPlans/schoolComponents/AcademicQuality'
export const navList = [
  {
    title: '一人一课表',
    component: onePersonOneTimetableComponent,
    componentName: 'onePersonOneTimetableComponent',
  },
  {
    title: '数据中台',
    component: dataPlatformComponent,
    componentName: 'dataPlatformComponent',
  },
  {
    title: '控量提质作业系统',
    component: qualityManageSystem,
    componentName: 'qualityManageSystem',
  },
  // {
  //   title: '“五育并举”评价系统',
  //   component: fiveEduComment,
  //   componentName: 'fiveEduComment',
  // },
  {
    title: '智笔课堂互动系统',
    component: smartClassroom,
    componentName: 'smartClassroom',
  },
  {
    title: '校园安全综合管理平台',
    component: safetySchool,
    componentName: 'safetySchool',
  },
  // {
  //   title: '晓慧XMUI云屏',
  //   component: aICloudScreen,
  //   componentName: 'aICloudScreen',
  // },
  // {
  //   title: '晓慧智慧云黑板',
  //   component: AICloudBlackboard,
  //   componentName: 'AICloudBlackboard',
  // },
  {
    title: '校园智能物联管控平台',
    component: Monitor,
    componentName: 'Monitor',
  },
  {
    title: '晓羊学苑',
    component: College,
    componentName: 'College',
  },
  {
    title: '教师发展平台',
    component: Teacher,
    componentName: 'Teacher',
  },
  {
    title: '学业质量管理平台',
    component: AcademicQuality,
    componentName: 'AcademicQuality',
  },
]
export const swiperList = [
  {
    url: onePersonOneClass,
    title: '校端产品',
    subTitle: '「 一人一课表 」',
    // title: '校端产品 「 晓羊一人一课表 」',
    desc: '依托强大的智能算法，支持传统行政班排课和分层走班排选课，让排课变得更简单',
  },
  {
    url: dataPlatform,
    // title: '校端产品 「 晓羊数据中台 」',
    title: '校端产品',
    subTitle: '「 数据中台 」',
    desc: '打破数据孤岛，提升数据价值，让数据成为教育资产',
  },
  {
    url: manageSystem,
    title: '校端产品',
    subTitle: '「 控量提质作业系统 」',
    desc: '专注于“双减”政策下的学业大数据釆集、分析和应用，助力学校打造大数据精准教学应用场景',
  },
  // {
  //   url: evaluateSystem,
  //   title: '校端产品 「 “五育并举”评价系统 」',
  //   desc: '基于国家对中小学生“德智体美劳”全面发展的培养要求，以大数据为核心，构建教育评价的全业务链条新生态',
  // },
  {
    url: smartRoom,
    title: '校端产品',
    subTitle: '「 智笔课堂互动系统 」',
    desc: '覆盖课前、课中、课后，以教学资源为内容，以课中纸笔互动为手段，构建创新的智慧互动课堂',
  },
  {
    url: safetyschool,
    // title: '校端产品 「 智校安-平安校园管理平台 」',
    title: '校端产品',
    subTitle: '「 校园安全综合管理平台 」',
    desc: '以数据、业务、AI三大平台能力为核心，为学校各场景的安全管理工作提供数据分析、评价和预测',
  },
  // {
  //   url: aiScreen,
  //   // title: '校端产品 「 晓慧AI多场景云屏系统 」',
  //   title: '校端产品',
  //   subTitle: '「 晓慧XMUI云屏 」',
  //   desc: '数十种应用场景，结合"晓慧同学"语音技术，让师生与云屏实时交流，构建"Anyone、Angtime、Anywhere、Any Device"4A智慧校园',
  // },
  // {
  //   url: aiBlack,
  //   // title: '校端产品 「 晓慧教学管理融合型AI云黑板 」',
  //   title: '校端产品',
  //   subTitle: '「 晓慧智慧云黑板 」',
  //   desc: '完美实现"大屏教学、小屏管理;一板融合、分屏智显"，给教室最智慧纯净的黑板生态',
  // },
  {
    url: manager,
    // title: '校端产品 「 晓羊校园能耗监控管理系统 」',
    title: '校端产品',
    subTitle: '「 校园智能物联管控平台 」',
    desc: '远程控制——解决管理麻烦，全面掌握——解决能源浪费，精准分析——解决安全隐患',
  },
  {
    url: collegeSystem,
    title: '校端产品',
    subTitle: '「 晓羊学苑 」',
    desc: '以解决教师教育教学中存在的实际问题为核心目标，做最贴近一线教师需求的学习资源',
  },
  {
    url: teacherSystem,
    title: '校端产品',
    subTitle: '「 教师发展平台 」',
    desc: '为学校提供教师全链条实用性的信息化工具和全方位专业化的网络服务',
  },
  {
    url: qcademicQuality,
    title: '校端产品',
    subTitle: '「 学业质量管理平台 」',
    desc: '专注于“双减”政策下，课后全场景学情数据的采集、分析与精准 教学应用',
  },
]
export const opoAdvantages = [
  {
    icon: 'icon-star-smile-line',
    title: '提供策略定制化服务',
    content: '基于学校特色及资源,个性化定制排课策略。',
    img: opotc1,
  },
  {
    icon: 'icon-curriculum-line',
    title: '日常课表管理功能全面',
    content: '支持日常调代课,变更通知自动推送相关教师和学生。',
    img: opotc2,
  },
  {
    icon: 'icon-curriculum-search-line',
    title: '完善的课表查询功能',
    content: '系统可自动生成10种以上学校常用的表格,并支持Al语音查询课表。',
    img: opotc3,
  },
  {
    icon: 'icon-paper-line',
    title: '可覆盖学校所有排选课需求',
    content:
      '支持走班排课(先选后排、先排后选)、校本抢课、行政班排课、学生分班选课和自定义选课规则设置。',
    img: opotc4,
  },
  {
    icon: 'icon-Cube-line',
    title: '拥有优质的客户服务体系',
    content: '定期分析客户数据特点,主动推送解决方案。',
    img: opotc5,
  },
  {
    icon: 'icon-finger-circle-line',
    title: '系统操作便捷',
    content: '系统采用排课流程引导式的操作设计,教务老师可以很快独立操作系统。',
    img: opotc6,
  },
]
