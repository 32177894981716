<template>
  <!-- 导航内容  -->
  <div class="content">
    <!--  一人一课表  -->
    <div class="top">
      <!-- <div class="c_top">该产品已累计服务 <span>9K+</span>所学校</div> -->
      <div class="clearfix c_top_content">
        <div class="float-left">
          <ul>
            <li>PRODUCT</li>
            <li><h4>校园安全综合管理平台</h4></li>
            <!-- <li>Campus Safety Integrated Management Platform</li> -->
          </ul>
          <div class="description">
            平台全面覆盖学校在安全管理的主要功能场景，具体包括校门口安全管理、宿舍管理、食堂管理智能预警、应急处理、安防体系建设、安全教育、视频汇聚、家校共育等板块。通过系统中台及中间件智能控制硬件终端设备，下发管理规则及采集汇总数据，进而通过工作流分解的方式，在移动端及智能交互终端将安全信息及工作信息推送到不同的用户角色，根据平台规则，不同的用户角色通过PC端、移动端、智能交互屏等终端方式进行后续工作处理，最后平台实时统计、汇总、分析相关数据，给与数据分析、评价及预测。
          </div>
        </div>
        <img
          class="float-right"
          src="@/assets/images/schoolProduct/SafetySchool/pic.png"
          alt="一人一课表"
        />
      </div>
    </div>
    <img
      class="img"
      src="@/assets/images/schoolProduct/SafetySchool/pic1.png"
      alt=""
    />
  </div>
</template>

<script>
import productContentCharacteristicPc from '@/assets/images/schoolProduct/school/characteristic_pc.png'
import productContentCharacteristicMobile from '@/assets/images/schoolProduct/school/characteristic_mobile.png'
export default {
  name: 'OnePersonOneTimetable',
  data() {
    return {
      productContentCharacteristicPc,
      productContentCharacteristicMobile,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/pages/product/school/SafetySchool.scss';
</style>
